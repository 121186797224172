// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chevrerie-js": () => import("/opt/build/repo/src/pages/chevrerie.js" /* webpackChunkName: "component---src-pages-chevrerie-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-fromagerie-js": () => import("/opt/build/repo/src/pages/fromagerie.js" /* webpackChunkName: "component---src-pages-fromagerie-js" */),
  "component---src-pages-fromages-js": () => import("/opt/build/repo/src/pages/fromages.js" /* webpackChunkName: "component---src-pages-fromages-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-vente-js": () => import("/opt/build/repo/src/pages/vente.js" /* webpackChunkName: "component---src-pages-vente-js" */)
}

